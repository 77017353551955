<template>
    <div class='head-box'>
        <div class='logo-box'>
            <div class='logo'><a href=''><Icon type="ios-pin" style="color:#CC9756;position:relative;bottom:1px;"/>{{logoname}}</a></div>
            <ul class='header-bar-ul'>
                <li v-if='logined'><span class='l-pad'>
                        <Icon type="ios-contact" />您好，{{myInfo.full_name}}</span><span class='l-pad'>已登录</span><span class='l-pad' @click='logout'>退出</span></li>
                <li v-else>您好，<a href='/login'>请登录</a><a href='/register' class='l-pad'>注册</a></li>
                <li v-if="ifAdmin||ifConfirm"><a href='/file/confirm'>文件确认</a></li>
                <li v-if="ifAdmin||ifVerify"><a href='/file/verify' >文件审核</a></li>
                <li v-if="ifAdmin||ifUpload"><a href='/file/upload' >文件上传</a></li>
                <li><a href='/index'>主页</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
let authority = [];
let authData = JSON.parse(localStorage.getItem("authData"));
let userToken = localStorage.getItem("current_user_token");
if (authData) {
  authority = authData.barRights;
}
    export default {
        name: 'headerBar',
        data() {
            return {
                logined: false,
                myInfo:{
                    full_name:'',
                    account:'',
                    role:[]
                }
            }
        },
        props: {
            logoname: {
                type: String,
                default: ""
            },
        },
        created() {
            
            if (userToken !== undefined && userToken !== null) {
                this.logined = true;
                this.getInfo();
            }
        },
        computed:{
            ifAdmin:function(){
                return authority.includes('roleManage')||authority.includes('super_all');
            },
            ifConfirm:function(){
                return authority.includes('fileConfirm');
            },
            ifVerify:function(){
                return authority.includes('fileVerify');
            },
            ifUpload:function(){
                return authority.includes('fileUpload');
            },

        },
        methods: {
            getInfo() {
      this.$axios
        .post(this.$url + "/user_info", {
          userToken: userToken,
          askData: "myInfo",
        })
        .then((response) => {
          if (response.data.stat == "ok") {
            this.myInfo = response.data.info;
          }
        });
    },
            logout: function () {
                localStorage.clear();
                this.logined = false;
                location.href = "/login";
            }
        }
    }
</script>

<style scoped>
    body {
        padding: 0;
        margin: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    span {
        cursor: pointer;
    }

    .head-box {
        width: 100%;
        height: 38px;
        background: rgb(34, 34, 54);
        color: rgb(176, 176, 176);
        min-width: 1280px;
    }

    .logo-box {
        width: 1280px;
        margin: 0 auto;
    }

    .logo {
        float: left;
        font-size: 12px;
        line-height: 36px;
        float: left;
    }

    .header-bar-ul {
        float: right;
        width: auto;
        height: 36px;
        line-height: 36px;
        margin: 0;
        padding: 0;
    }

    .header-bar-ul>li {
        margin-top: 12px;
        float: right;
        line-height: 12px;
        font-size: 12px;
        list-style: none;
        padding: 0 1em;
        height: 12px;
        border-right: solid 1px rgb(176, 176, 176);
    }

    .header-bar-ul>li:hover a {
        color: #eee;
    }

    li:first-child {
        border-right: none;
    }

    .l-pad {
        padding-left: 1em;
    }

    .high-light {
        color: #ffffff;
    }
</style>