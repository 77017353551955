<template>
  <div>
    <header-bar :logined="logined" :logoname="projectName"></header-bar>
    <div class="med-wrapper-box">
          <router-view name="leftBar"></router-view>
          <router-view></router-view>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import footerBar from "./FooterBar.vue";
import headerBar from "./HeaderBar.vue";
let theProject;
if(localStorage.getItem("project")){
  theProject = JSON.parse(localStorage.getItem("project"));
}
export default {
  name: "mainFrame",
  components: {
    footerBar,
    headerBar,
  },
  data() {
    return {
      logined: false,
      projectName:theProject?theProject.name:'',
    };
  },
  created() {
  },
  mounted() {},
  props: {
  },
  methods: {
  },
};
</script>

<style scoped>
.med-wrapper-box {
  margin: 0 auto;
  width: 100%;
  min-width: 1260px;
  padding-bottom: 60px;
  background-color: #fff;
  min-height: 800px;
}
</style>